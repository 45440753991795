import { flowRight } from 'lodash';
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from '../../../common/components/runtime-context';

import {
  HEADER_SHOW_ON_CATEGORY_PAGE_PATH,
  IS_CATEGORY_HEADER_ENABLED_PATH,
  SECTION_CATEGORY,
} from '@wix/communities-blog-client-common/dist/src/constants/wix-params';
import { HEADER_SHOW_DEFAULT } from '@wix/communities-blog-client-common/dist/src/constants/header-sizes';
import { IS_CATEGORY_HEADER_ENABLED_DEFAULT } from '../../constants/category-header';
import { getAppConfig } from '../../../common/store/app-config/app-config-selectors';
import { resolveCategorySlug } from '../../../common/services/slug';
import { getCategoryBySlug } from '../../../common/selectors/categories-selectors';
import { getIsCategoryLoaded } from '../../../common/store/is-loaded/is-loaded-selectors';
import { getIsCategoryLoading } from '../../../common/store/is-loading/is-loading-selectors';
import { getSortedPostsByCategoryId, getPostsByCategoryIdAndPage } from '../../../common/selectors/post-selectors';
import { isSite } from '../../../common/selectors/view-mode-selectors';
import { isExcludePostContentSupported } from '../../../common/selectors/layout-selectors';
import withAppSettings from '../../../common/hoc/with-app-settings';
import withAuth from '../../../common/hoc/with-auth';
import withLayoutProps from '../../hoc/with-layout-props';
import withPaginationSettings from '../../hoc/with-pagination-settings';
import withPermissions from '../../../common/hoc/with-permissions';
import withTranslate from '../../../common/hoc/with-translate';
// import AccountSuspended from '../../components/account-suspended';
import AnimatedLoader from '../../../common/components/animated-loader';
import AppLoaded from '../../../common/components/app-loaded';
import CategoryDescription from '../../components/category-description';
import CategoryHeader from '../../components/category-header';
import NoPosts from '../no-posts';
import Page from '../../../common/components/page';
import Pagination from '../../../common/containers/pagination';
import PostList from '../../components/post-list';
import PostListProGallery from '../../components/post-list-pro-gallery';
import { EmptyLayout } from '../../../common/components/layout';
import { createPageUrl, handleNavigation } from '../../../common/services/pagination';
import { getCategoryUrl } from '../../../common/services/get-category-url';
import { isLayoutProGallery } from '@wix/communities-blog-client-common/dist/src/constants/layout-types';

const Layout = EmptyLayout;

export class CategoryPage extends Component {
  state = {
    isAnimated: !this.props.allPosts.length,
  };

  // constructor(props) {
  //   super(props);
  //   // this.handleLikeClick = this.handleLikeClick.bind(this);
  //   this.state = {
  //     isAnimated: !props.allPosts.length,
  //   };
  // }

  // componentDidMount() {
  //   const { category, page, isAppLoaded } = this.props;

  //   if (isAppLoaded && page === 1) {
  //     this.fetchPosts(category._id);
  //   }
  // }

  componentWillUnmount() {
    const { category } = this.props;
    this.props.setIsLoaded('posts', category._id, false);
  }

  componentDidUpdate({ isAuthenticated, isBlocked, pageSize, excludeContent }) {
    const { category } = this.props;
    if (
      this.props.isAuthenticated !== isAuthenticated ||
      this.props.isBlocked !== isBlocked ||
      this.props.pageSize !== pageSize ||
      (excludeContent && !this.props.excludeContent)
    ) {
      this.fetchPosts(category._id);
    }
  }

  fetchPosts(categoryId, page = this.props.page) {
    if (this.props.isBlocked) {
      return;
    }

    this.props.fetchCategoryPosts({ categoryId, page, excludeContent: this.props.excludeContent });
  }

  loadMore = page => this.fetchPosts(this.props.category._id, page);

  renderPosts() {
    const {
      // isBlocked,
      currentPagePosts,
      allPosts,
      entityCount,
      category,
      isCategoryLoading,
      isCategoryLoaded,
      showCreatePostAction,
      page,
      layoutType,
      showPagination,
    } = this.props;
    const hasPosts = allPosts.length;

    // if (isBlocked) {
    //   return <AccountSuspended />;
    // }

    if (!hasPosts && isCategoryLoaded) {
      return <NoPosts category={category} />;
    }

    if (isLayoutProGallery(layoutType)) {
      return (
        <PostListProGallery
          pageStart={page + 1}
          entityCount={entityCount}
          loadMore={showPagination ? undefined : this.loadMore}
          allPosts={showPagination ? currentPagePosts : allPosts}
          currentPagePosts={currentPagePosts}
          isLoading={isCategoryLoading}
          showCreatePostAction={showCreatePostAction}
        />
      );
    }

    return (
      <PostList
        pageStart={page + 1}
        layoutType={layoutType}
        entityCount={entityCount}
        loadMore={showPagination ? undefined : this.loadMore}
        allPosts={showPagination ? currentPagePosts : allPosts}
        currentPagePosts={currentPagePosts}
        isLoading={isCategoryLoading}
        category={category}
        isLoaded={isCategoryLoaded}
        showCreatePostAction={showCreatePostAction}
      />
    );
  }

  renderPagination() {
    const { entityCount, pageSize, page, categoryUrl, isSite, categoryPath } = this.props;
    return (
      <Pagination
        page={page}
        pageSize={pageSize}
        entityCount={entityCount}
        createPageUrl={page => createPageUrl(page, categoryUrl)}
        handleNavigation={handleNavigation(categoryPath, isSite)}
      />
    );
  }

  render() {
    const {
      category,
      isCategoryLoading,
      isCategoryLoaded,
      allPosts,
      showHeader,
      isCategoryHeaderEnabled,
      showPagination,
    } = this.props;

    const isLoading = isCategoryLoading && !isCategoryLoaded;

    return (
      <Page noSpacing>
        <Layout>
          {showHeader && <CategoryDescription category={category} headerSize={200} />}
          {isCategoryHeaderEnabled && <CategoryHeader category={category} />}
          <AnimatedLoader isLoading={isLoading && !allPosts.length} isAnimated={this.state.isAnimated}>
            {this.renderPosts()}
          </AnimatedLoader>
          {showPagination && !isLoading && this.renderPagination()}
          {isCategoryLoaded && <AppLoaded />}
        </Layout>
      </Page>
    );
  }
}

CategoryPage.propTypes = {
  categoryUrl: PropTypes.string.isRequired,
  categoryPath: PropTypes.string.isRequired,
  fetchCategoryPosts: PropTypes.func.isRequired,
  categoryId: PropTypes.string, // no id when creating new category in editor
  setIsLoaded: PropTypes.func.isRequired,
  allPosts: PropTypes.array,
  currentPagePosts: PropTypes.array,
  category: PropTypes.object,
  isAuthenticated: PropTypes.bool,
  isBlocked: PropTypes.bool,
  params: PropTypes.object,
  t: PropTypes.func,
  newPostId: PropTypes.string,
  isCategoryLoading: PropTypes.bool,
  isCategoryLoaded: PropTypes.bool,
  isSite: PropTypes.bool,
  showCreatePostAction: PropTypes.bool.isRequired,
  layoutType: PropTypes.number.isRequired,
  showHeader: PropTypes.bool,
  isCategoryHeaderEnabled: PropTypes.bool,
  excludeContent: PropTypes.bool,
  pageSize: PropTypes.number,
  entityCount: PropTypes.number,
  showPagination: PropTypes.bool,
  useCategoryMenuLabelForMetadataTitle: PropTypes.bool,
};

CategoryPage.defaultProps = {
  showHeader: HEADER_SHOW_DEFAULT,
  isCategoryHeaderEnabled: IS_CATEGORY_HEADER_ENABLED_DEFAULT,
};

const mapRuntimeToProps = (state, ownProps, actions, host) => {
  const categorySlug = resolveCategorySlug(ownProps.params);
  const category = getCategoryBySlug(state, categorySlug) || {};
  const categoryId = category._id;
  const currentPagePosts = categoryId ? getPostsByCategoryIdAndPage(state, categoryId, ownProps.page) : [];
  const { categoryPath, useCategoryMenuLabelForMetadataTitle, sectionUrl } = getAppConfig(state);
  const categoryUrl = getCategoryUrl(sectionUrl, categoryPath, categorySlug);

  return {
    categoryUrl,
    categoryPath: `/${categoryPath}/${categorySlug}/`,
    category,
    allPosts: categoryId ? getSortedPostsByCategoryId(state, categoryId) : [],
    currentPagePosts,
    newPostId: state.newPostMessage,
    categoryId,
    isCategoryLoading: getIsCategoryLoading(state, categoryId),
    isCategoryLoaded: getIsCategoryLoaded(state, categoryId),
    isSite: isSite(state),
    showCreatePostAction: isSite(state) && ownProps.canSee('create', 'post'),
    excludeContent: isExcludePostContentSupported(state),
    useCategoryMenuLabelForMetadataTitle,
    setIsLoaded: actions.setIsLoaded,
    fetchCategoryPosts: actions.fetchCategoryPostsPromisified,
  };
};

export default flowRight(
  withPermissions,
  withPaginationSettings(SECTION_CATEGORY),
  connect(mapRuntimeToProps),
  withLayoutProps(),
  withAuth,
  withAppSettings({
    showHeader: HEADER_SHOW_ON_CATEGORY_PAGE_PATH,
    isCategoryHeaderEnabled: IS_CATEGORY_HEADER_ENABLED_PATH,
  }),
  withTranslate,
)(CategoryPage);
