import { trim, trimEnd } from 'lodash';
import { BLOG_HEADER_TOTAL_RESULTS } from '@wix/communities-blog-client-common/dist/src/constants/headers';
import { BLOG_APP_ID } from '@wix/communities-universal/dist/src/constants/appsConfig';
import { BLOG_PAGE_ID } from '@wix/communities-blog-client-common/dist/src/constants/component-ids';
import { getHeader } from './get-header';
import { getRawOuterUrl } from './get-outer-url';
import Wix from './wix-sdk-polyfill';

export const buildPaginationRequestParams = (page, pageSize) => {
  if (page !== undefined) {
    return {
      offset: (page - 1) * pageSize,
      size: pageSize,
    };
  }
  return {};
};

export const getLastPage = (entityCount, pageSize = 1) => {
  return Math.ceil(entityCount / pageSize);
};

export const getTotalResults = headers => parseInt(getHeader(headers, BLOG_HEADER_TOTAL_RESULTS), 10);

export const formatTitle = ({ title, page, lastPage, t }) =>
  page < 2 ? title : `${title} | ${t('pagination.current-position-tile', { page, lastPage })}`;

const numberToPage = page => (page > 1 ? `/page/${page}/` : '/');

export const createPageUrl = (page, sectionUrl) => getRawOuterUrl(numberToPage(page), sectionUrl).toString();

export const createArchivePageUrl = (sectionUrl, year, month, page) =>
  createPageUrl(page, `${trimEnd(sectionUrl, '/')}/archive/${year}/${month}`);

export const handleNavigation = (path, isSite) => (event, page) => {
  if (!isSite) {
    event.preventDefault();
    Wix.Utils.navigateToSection(
      {
        appDefinitionId: BLOG_APP_ID,
        sectionId: BLOG_PAGE_ID,
        shouldRefreshIframe: false,
      },
      trim(path, '/') + numberToPage(page),
    );
  }
};
