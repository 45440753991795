import React from 'react';
import { Interpolate } from 'react-i18next';
import Wix from '../../services/wix-sdk-polyfill';

export const POST_RESTORE = 'post-restore';
export const POST_MOVE_TO_TRASH = 'post-move-to-trash';
export const POST_DELETE = 'post-delete';
export const POST_DELETE_ANOTHER_WRITER_EDITING_ERROR = 'post-delete-another-writer-editing-error';
export const POST_SUBSCRIBED = 'post-subscribe';
export const POST_UNSUBSCRIBED = 'post-unsubscribe';
export const POST_ENABLE_COMMENTING = 'post-enable-commenting';
export const POST_DISABLE_COMMENTING = 'post-disable-commenting';
export const POST_PUBLISH = 'post-publish';
export const POST_UPDATE = 'post-update';
export const POST_REVERTED_TO_DRAFT = 'post-reverted-to-draft';
export const POST_CHANGES_DISCARDED = 'post-changes-discarded';
export const POST_CHANGES_NOT_PUBLISHED = 'post-changes-not-published';
export const NOT_PUBLISHED_BLOG_POST_PUBLISH = 'not-published-blog-post-publish';
export const POST_MOVE = 'post-move';
export const POST_CHANGE_CATEGORY = 'post-category-change';
export const POST_DUPLICATE = 'post-duplicate';
export const POST_PIN = 'post-pin';
export const POST_UNPIN = 'post-unpin';

export const DRAFT_MOVE_TO_TRASH = 'draft-move-to-trash';
export const DRAFT_DELETE = 'draft-delete';
export const DRAFT_DUPLICATE = 'draft-duplicate';

export const SCHEDULE = 'schedule';
export const UNSCHEDULE = 'unschedule';

export const COMMENT_UPDATE = 'comment-update';
export const COMMENT_DELETE = 'comment-delete';

export const REQUIRE_POST_FIELDS = 'require-post-fields';

export const REQUIRE_CATEGORY_FIELDS = 'require-category-fields';

export const MEMBER_PROMOTE_WRITER = 'member-promote-moderator';
export const MEMBER_DEMOTE_WRITER = 'member-demote-moderator';

export const MEMBER_BLOCK = 'member-block';
export const MEMBER_UNBLOCK = 'member-unblock';

export const CATEGORY_CREATE = 'category-create';
export const CATEGORY_DELETE = 'category-delete';
export const CATEGORY_UPDATE = 'category-update';
export const CATEGORY_SUBSCRIBED = 'category-subscribed';
export const CATEGORY_UNSUBSCRIBED = 'category-unsubscribed';

export const REQUIRE_PROFILE_FIELDS = 'require-profile-fields';
export const PROFILE_UPDATE = 'profile-update';
export const PROFILE_UPDATE_FAILED = 'profile-update-failed';

export const USER_SUBSCRIBED = 'user-subscribe';
export const USER_UNSUBSCRIBED = 'user-unsubscribe';

export const DEMO_MODE_SITE = 'demo-mode-site';
export const DEMO_MODE_EDITOR = 'demo-mode-editor';
export const DEMO_MODE_PREVIEW = 'demo-mode-preview';

export const SETTINGS_UPDATED = 'settings-updated';

export const LOGO_SAVED = 'logo-saved';

export default {
  [POST_RESTORE]: {
    content: () => <Interpolate i18nKey="messages.post-restored" useDangerouslySetInnerHTML={true} />,
    type: 'success',
    messageKey: 'messages.post-restored',
  },
  [POST_MOVE_TO_TRASH]: {
    content: () => <Interpolate i18nKey="messages.post-moved-to-trash" useDangerouslySetInnerHTML={true} />,
    type: 'success',
    messageKey: 'messages.post-moved-to-trash',
  },
  [POST_PIN]: {
    content: () => <Interpolate i18nKey="messages.post-pinned" useDangerouslySetInnerHTML={true} />,
    type: 'info',
  },
  [POST_UNPIN]: {
    content: () => <Interpolate i18nKey="messages.post-unpinned" useDangerouslySetInnerHTML={true} />,
    type: 'info',
  },
  [POST_DELETE]: {
    content: () => <Interpolate i18nKey="messages.post-deleted" useDangerouslySetInnerHTML={true} />,
    type: 'success',
    messageKey: 'messages.post-deleted',
  },
  [POST_DELETE_ANOTHER_WRITER_EDITING_ERROR]: {
    content: () => (
      <Interpolate i18nKey="messages.post-delete-another-writer-editing-error" useDangerouslySetInnerHTML={true} />
    ),
    type: 'error',
    messageKey: 'messages.post-delete-another-writer-editing-error',
  },
  [POST_PUBLISH]: {
    content: () => <Interpolate i18nKey="messages.post-published" useDangerouslySetInnerHTML={true} />,
    type: 'success',
    messageKey: 'messages.post-published',
    linkMessageKey: 'messages.post-published-link',
  },
  [POST_REVERTED_TO_DRAFT]: {
    content: () => <Interpolate i18nKey="messages.post-reverted-to-draft" useDangerouslySetInnerHTML={true} />,
    type: 'success',
    messageKey: 'messages.post-reverted-to-draft',
  },
  [POST_CHANGES_DISCARDED]: {
    content: () => <Interpolate i18nKey="messages.post-changes-discarded" useDangerouslySetInnerHTML={true} />,
    type: 'success',
    messageKey: 'messages.post-changes-discarded',
  },
  [POST_CHANGES_NOT_PUBLISHED]: {
    content: () => <Interpolate i18nKey="messages.post-changes-not-published" useDangerouslySetInnerHTML={true} />,
    type: 'info',
    messageKey: 'messages.post-changes-not-published',
  },
  [POST_UPDATE]: {
    content: () => <Interpolate i18nKey="messages.post-updated" useDangerouslySetInnerHTML={true} />,
    type: 'success',
    messageKey: 'messages.post-updated',
  },
  [NOT_PUBLISHED_BLOG_POST_PUBLISH]: {
    type: 'info',
    messageKey: 'messages.not-published-blog-post-published',
  },
  [POST_ENABLE_COMMENTING]: {
    content: () => <Interpolate i18nKey="messages.commenting-enabled" useDangerouslySetInnerHTML={true} />,
    type: 'info',
    messageKey: 'messages.commenting-enabled',
  },
  [POST_DISABLE_COMMENTING]: {
    content: () => <Interpolate i18nKey="messages.commenting-disabled" useDangerouslySetInnerHTML={true} />,
    type: 'info',
    messageKey: 'messages.commenting-disabled',
  },
  [POST_DUPLICATE]: {
    content: () => <Interpolate i18nKey="messages.post-duplicated" useDangerouslySetInnerHTML={true} />,
    type: 'success',
    messageKey: 'messages.post-duplicated',
  },
  [DRAFT_MOVE_TO_TRASH]: {
    content: () => <Interpolate i18nKey="messages.draft-moved-to-trash" useDangerouslySetInnerHTML={true} />,
    type: 'success',
    messageKey: 'messages.draft-moved-to-trash',
  },
  [DRAFT_DELETE]: {
    content: () => <Interpolate i18nKey="messages.draft-deleted" useDangerouslySetInnerHTML={true} />,
    type: 'success',
    messageKey: 'messages.draft-deleted',
  },
  [DRAFT_DUPLICATE]: {
    content: () => <Interpolate i18nKey="messages.draft-duplicated" useDangerouslySetInnerHTML={true} />,
    type: 'success',
    messageKey: 'messages.draft-duplicated',
  },
  [UNSCHEDULE]: {
    content: () => <Interpolate i18nKey="messages.unschedule" useDangerouslySetInnerHTML={true} />,
    type: 'success',
    messageKey: 'messages.unschedule',
  },
  [SCHEDULE]: {
    content: (t, props) => <Interpolate i18nKey="messages.schedule" {...props} useDangerouslySetInnerHTML={true} />,
    type: 'success',
    messageKey: 'messages.schedule',
    createVariables: (date, time) => ({
      date: date,
      time: time,
    }),
  },
  [USER_SUBSCRIBED]: {
    content: () => <Interpolate i18nKey="messages.user-subscribed" useDangerouslySetInnerHTML={true} />,
    type: 'info',
  },
  [USER_UNSUBSCRIBED]: {
    content: () => <Interpolate i18nKey="messages.user-unsubscribed" useDangerouslySetInnerHTML={true} />,
    type: 'info',
  },
  [POST_SUBSCRIBED]: {
    content: () => <Interpolate i18nKey="messages.post-subscribed" useDangerouslySetInnerHTML={true} />,
    type: 'info',
  },
  [POST_UNSUBSCRIBED]: {
    content: () => <Interpolate i18nKey="messages.post-unsubscribed" useDangerouslySetInnerHTML={true} />,
    type: 'info',
  },
  [POST_MOVE]: {
    content: () => <Interpolate i18nKey="messages.post-moved" useDangerouslySetInnerHTML={true} />,
  },
  [POST_CHANGE_CATEGORY]: {
    content: () => <Interpolate i18nKey="messages.post-category-changed" useDangerouslySetInnerHTML={true} />,
  },

  [COMMENT_UPDATE]: {
    content: () => <Interpolate i18nKey="messages.comment-updated" useDangerouslySetInnerHTML={true} />,
    type: 'info',
  },
  [COMMENT_DELETE]: {
    content: () => <Interpolate i18nKey="messages.comment-deleted" useDangerouslySetInnerHTML={true} />,
    type: 'info',
  },

  [REQUIRE_POST_FIELDS]: {
    content: (t, props) => {
      const key = props && props.sort().join('-');
      switch (key) {
        case 'title':
          return <Interpolate i18nKey="messages.post-title-required" useDangerouslySetInnerHTML={true} />;
        case 'content':
          return <Interpolate i18nKey="messages.post-content-required" useDangerouslySetInnerHTML={true} />;
        default:
          return <Interpolate i18nKey="messages.post-fields-required" useDangerouslySetInnerHTML={true} />;
      }
    },
    type: 'error',
  },

  [REQUIRE_CATEGORY_FIELDS]: {
    content: (t, props) => {
      const key = props && props.sort().join('-');
      switch (key) {
        case 'label':
          return <Interpolate i18nKey="messages.category-title-required" useDangerouslySetInnerHTML={true} />;
        default:
          return <Interpolate i18nKey="messages.category-fields-required" useDangerouslySetInnerHTML={true} />;
      }
    },
    type: 'error',
  },

  [MEMBER_PROMOTE_WRITER]: {
    content: () => <Interpolate i18nKey="messages.user-promoted-writer" useDangerouslySetInnerHTML={true} />,
  },
  [MEMBER_DEMOTE_WRITER]: {
    content: () => <Interpolate i18nKey="messages.user-demoted-writer" useDangerouslySetInnerHTML={true} />,
  },

  [MEMBER_BLOCK]: {
    content: () => <Interpolate i18nKey="messages.user-blocked" useDangerouslySetInnerHTML={true} />,
  },
  [MEMBER_UNBLOCK]: {
    content: () => <Interpolate i18nKey="messages.user-unblocked" useDangerouslySetInnerHTML={true} />,
  },

  [CATEGORY_DELETE]: {
    content: () => <Interpolate i18nKey="messages.category-deleted" useDangerouslySetInnerHTML={true} />,
    messageKey: 'messages.category-deleted',
  },
  [CATEGORY_CREATE]: {
    content: () => <Interpolate i18nKey="messages.category-created" useDangerouslySetInnerHTML={true} />,
    type: 'success',
    messageKey: 'messages.category-created',
  },
  [CATEGORY_UPDATE]: {
    content: () => <Interpolate i18nKey="messages.category-updated" useDangerouslySetInnerHTML={true} />,
    type: 'success',
    messageKey: 'messages.category-updated',
  },
  [CATEGORY_SUBSCRIBED]: {
    content: () => <Interpolate i18nKey="messages.category-subscribed" useDangerouslySetInnerHTML={true} />,
  },
  [CATEGORY_UNSUBSCRIBED]: {
    content: () => <Interpolate i18nKey="messages.category-unsubscribed" useDangerouslySetInnerHTML={true} />,
  },

  [REQUIRE_PROFILE_FIELDS]: {
    content: (t, props) => {
      const { name, image, cover } = props;
      if (name) {
        return <Interpolate i18nKey="messages.profile-name-required" useDangerouslySetInnerHTML={true} />;
      }
      if (image) {
        return <Interpolate i18nKey="messages.profile-image-uploading" useDangerouslySetInnerHTML={true} />;
      }
      if (cover) {
        return <Interpolate i18nKey="messages.profile-cover-uploading" useDangerouslySetInnerHTML={true} />;
      }
      return <Interpolate i18nKey="messages.profile-unknown-error" useDangerouslySetInnerHTML={true} />;
    },
    type: 'error',
  },
  [PROFILE_UPDATE]: {
    content: () => <Interpolate i18nKey="messages.profile-updated" useDangerouslySetInnerHTML={true} />,
  },
  [PROFILE_UPDATE_FAILED]: {
    content: () => <Interpolate i18nKey="messages.profile-update-failed" useDangerouslySetInnerHTML={true} />,
    type: 'error',
  },

  [DEMO_MODE_SITE]: {
    content: () => <Interpolate i18nKey="messages.demo-mode-site" useDangerouslySetInnerHTML={true} />,
    type: 'info',
  },
  [DEMO_MODE_EDITOR]: {
    content: () => <Interpolate i18nKey="messages.demo-mode-editor" useDangerouslySetInnerHTML={true} />,
    type: 'info',
  },
  [DEMO_MODE_PREVIEW]: {
    content: t => (
      <Interpolate
        i18nKey="messages.demo-mode-preview"
        activate={
          <span
            style={{ cursor: 'pointer', textDecoration: 'underline' }}
            onClick={() => Wix.Preview.openSettingsDialog()}
          >
            {t('messages.demo-mode-preview-activate')}
          </span>
        }
        useDangerouslySetInnerHTML={true}
      />
    ),
    type: 'info',
  },
  [SETTINGS_UPDATED]: {
    content: () => <Interpolate i18nKey="messages.settings-saved" useDangerouslySetInnerHTML={true} />,
    type: 'success',
    messageKey: 'messages.settings-saved',
  },
  [LOGO_SAVED]: {
    content: () => <Interpolate i18nKey="messages.logo-saved" useDangerouslySetInnerHTML={true} />,
    type: 'success',
    messageKey: 'messages.logo-saved',
  },
};
