import { flowRight } from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from '../../../common/components/runtime-context';
import { ModalCentered } from '../../../common/components/modals/base/modal-centered';
import DeleteConfirmation from '../../../common/components/delete-confirmation';
import withTranslate from '../../../common/hoc/with-translate';

export const DeletePostModal = ({ t, closeModal, deletePost }) => (
  <ModalCentered onClose={closeModal}>
    <DeleteConfirmation
      title={t('delete-post-modal.delete-post')}
      message={t('delete-post-modal.will-detele-comments')}
      onConfirm={deletePost}
      onCancel={closeModal}
    />
  </ModalCentered>
);

DeletePostModal.propTypes = {
  t: PropTypes.func.isRequired,
  closeModal: PropTypes.func.isRequired,
  deletePost: PropTypes.func.isRequired,
  postId: PropTypes.string.isRequired,
  postStatus: PropTypes.string.isRequired,
};

const mapRuntimeToProps = (state, ownProps) => ({
  deletePost: () =>
    ownProps.closeModal({
      postId: ownProps.postId,
      postStatus: ownProps.postStatus,
    }),
});

export default flowRight(
  connect(mapRuntimeToProps),
  withTranslate,
)(DeletePostModal);
