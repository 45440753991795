import { flowRight } from 'lodash';
import { connect } from '../../common/components/runtime-context';
import withHocName from '../../common/hoc/with-hoc-name';

const getFontClassName = (property, headerSize) =>
  !headerSize ? '' : `blog-page-description-${property}-header${headerSize}-font`;
const mapRuntimeToProps = (state, ownProps) => {
  return {
    titleFontClassName: getFontClassName('title', ownProps.headerSize),
    descriptionFontClassName: getFontClassName('content', ownProps.headerSize),
  };
};

export default flowRight(
  connect(mapRuntimeToProps),
  withHocName('WithHeaderFontSize'),
);
