import { flowRight, map, toString } from 'lodash';
import extractHashtags from './extract-hashtags';
import { getContentText } from './content-text';

const mapHashtags = hashtags => map(hashtags, hashtagData => hashtagData.hashtag);
const mapToLowerCase = hashtags => map(hashtags, hashtag => hashtag.toLowerCase());

export const extractHashtagsFromText = flowRight(
  mapToLowerCase,
  mapHashtags,
  extractHashtags,
  toString,
);

export const extractHashtagsFromContent = flowRight(
  mapToLowerCase,
  mapHashtags,
  extractHashtags,
  getContentText,
);
