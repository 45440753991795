import { flowRight } from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import className from 'classnames';
import { connect } from '../../../common/components/runtime-context';
import { COMMENT_ACTIONS } from '../../constants/comment-actions';
import CommentAvatar from '../comment-avatar';
import { MoreButton } from '../../../common/components/more-button';
import CommentActions from '../comment-actions';
import TimeAgo from '../../../common/components/time-ago';
import ProfileLink from '../../../common/components/link/profile-link';
import withPermissions from '../../../common/hoc/with-permissions';
import { getCommentActions } from '../../services/comment-actions';
import styles from './comment-header.scss';

const renderMoreButton = (comment, visibleActions) => {
  return (
    <div className={styles.moreButton}>
      <MoreButton>
        <CommentActions comment={comment} visibleActions={visibleActions} />
      </MoreButton>
    </div>
  );
};

const CommentHeader = ({ comment, showMoreButton, type, visibleActions }) => (
  <div
    className={className(
      styles.commentHeader,
      styles[type],
      { [styles.withMoreButton]: showMoreButton },
      'comment-header',
    )}
  >
    <object type="communities/link" className={styles.commentAvatar}>
      <ProfileLink user={comment.owner}>
        <CommentAvatar user={comment.owner} type={type} />
      </ProfileLink>
    </object>
    <span className={styles.commentTime}>
      <TimeAgo time={comment.createdDate} />
    </span>
    {showMoreButton && renderMoreButton(comment, visibleActions)}
  </div>
);

CommentHeader.SEARCH = 'search';

CommentHeader.propTypes = {
  comment: PropTypes.object.isRequired,
  showMoreButton: PropTypes.bool,
  type: PropTypes.string,
  visibleActions: PropTypes.arrayOf(PropTypes.string),
};

const mapRuntimeToProps = (state, { comment, canSee, visibleActions }) => {
  const commentActions = getCommentActions({
    comment,
    canSee,
    visibleActions: visibleActions || COMMENT_ACTIONS,
    enableShare: true,
  });

  return {
    showMoreButton: Boolean(commentActions.length),
  };
};

export default flowRight(
  withPermissions,
  connect(mapRuntimeToProps),
)(CommentHeader);
