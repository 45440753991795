import { compact, flowRight, includes, noop } from 'lodash';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from '../runtime-context';
import {
  POST_ACTIONS,
  POST_ACTION_TOGGLE_COMMENTS,
  POST_ACTION_EDIT,
  POST_ACTION_SHARE,
  POST_ACTION_PIN,
  POST_ACTION_DELETE,
  POST_ACTION_DUPLICATE,
  POST_ACTION_SUBSCRIBE,
} from '@wix/communities-blog-client-common/dist/src/constants/post-actions';
import { PinIcon } from '../icons/pin-icon';
import { DeleteIcon } from '../icons/delete-icon';
import { ShareIcon } from '../icons/share-icon';
import { CommentingDisabledIcon } from '../icons/commenting-disabled-icon';
import { CommentingEnabledIcon } from '../icons/commenting-enabled-icon';
import { EditIcon } from '../icons/edit-icon';
import { SubscribeIcon } from '../icons/subscribe-icon';
import { DuplicateIcon } from '../icons/duplicate-icon';
import ActionButton from '../../../post-page/components/action-button';
import {
  MODAL_TYPE_DELETE_POST,
  MODAL_TYPE_POST_EDITOR_DEPRECATION,
  MODAL_TYPE_SHARE_POST,
} from '@wix/communities-blog-client-common/dist/src/constants/modal-types';
import { POST_TITLE_MAXLENGTH } from '@wix/communities-blog-universal/dist/src/constants/post';

import {
  POST_PIN,
  POST_UNPIN,
  POST_ENABLE_COMMENTING,
  POST_DISABLE_COMMENTING,
  POST_SUBSCRIBED,
  POST_UNSUBSCRIBED,
} from '../message/messages';
import withPermissions from '../../hoc/with-permissions';
import withTranslate from '../../hoc/with-translate';
import withDeviceType from '../../hoc/with-device-type';
import withAuth from '../../hoc/with-auth';
import withFeedMetadataSettings from '../../hoc/with-feed-metadata-settings';
import { getCategory } from '../../selectors/categories-selectors';
import { getIsMemberAreaInstalled } from '../../store/communities-context/communities-context-selectors';
import { isPostEditorDeprecated } from '../../selectors/experiments-selectors';
import { isSite } from '../../selectors/view-mode-selectors';
import { getPostActions } from '../../services/post-actions';

export class PostActions extends Component {
  constructor(props) {
    super(props);
    this.firstButtonRef = React.createRef();
  }

  componentDidMount() {
    this.props.actionsOpened({ type: 'post' });
    // TODO: fix accessibility: this.firstButtonRef.current.focus() scrolls a whole page.
    // this.firstButtonRef.current && this.firstButtonRef.current.focus();
  }

  componentWillUnmount() {
    this.props.actionsClosed({ type: 'post' });
  }

  handlePin = () => {
    const {
      post: { _id, isPinned },
      buttonClicked,
      pinPost,
      unpinPost,
      showMessage,
    } = this.props;

    buttonClicked({ action: 'pin', flag: Number(!isPinned) });
    if (isPinned) {
      unpinPost(_id).then(() => showMessage(POST_UNPIN));
    } else {
      pinPost(_id).then(() => showMessage(POST_PIN));
    }
  };

  handleSubscription = () => {
    const {
      post: { _id, isSubscribed },
      buttonClicked,
      subscribe,
      unsubscribe,
      showMessage,
    } = this.props;

    buttonClicked({ action: 'follow_post', flag: Number(!isSubscribed) });
    if (isSubscribed) {
      unsubscribe({ _id, type: 'post' }).then(() => showMessage(POST_UNSUBSCRIBED));
    } else {
      subscribe({ _id, type: 'post' }).then(() => showMessage(POST_SUBSCRIBED));
    }
  };

  handleComments = () => {
    const {
      post: { _id, isCommentsDisabled },
      buttonClicked,
      enablePostComments,
      disablePostComments,
      showMessage,
    } = this.props;

    buttonClicked({ action: 'lock', flag: Number(!isCommentsDisabled) });
    if (isCommentsDisabled) {
      enablePostComments(_id).then(() => showMessage(POST_ENABLE_COMMENTING));
    } else {
      disablePostComments(_id).then(() => showMessage(POST_DISABLE_COMMENTING));
    }
  };

  showModal = type => {
    const props = {
      postId: this.props.post._id,
      postSlug: this.props.post.slug,
      postStatus: this.props.post.status,
    };
    const { buttonClicked, openModal, focusOnCloseId } = this.props;

    return () => {
      buttonClicked({ action: type, postId: props.postId });
      openModal(type, props);
    };
  };

  handleGoToEditPage = () => {
    const {
      buttonClicked,
      isPostEditorDeprecated,
      openModal,
      goToEditPage,
      post: { _id },
    } = this.props;

    buttonClicked({ action: 'edit' });

    if (isPostEditorDeprecated) {
      openModal(MODAL_TYPE_POST_EDITOR_DEPRECATION);
    } else {
      goToEditPage(_id);
    }
  };

  handleDuplicatePost = () => {
    const { duplicatePost, goToEditPage, post, t } = this.props;
    const { title, content } = post;
    const draftTitle = `${t('post-actions.duplicate-post-copy-of')} ${title}`.substring(0, POST_TITLE_MAXLENGTH);

    duplicatePost({ title: draftTitle, content }).then(({ _id }) => goToEditPage(_id));
  };

  getFirstButtonRef() {
    const isRefSet = !!this.firstButtonRef.current;
    return isRefSet ? null : this.firstButtonRef;
  }

  getButtons = () => {
    const { post, t, postActions, forPublicUser, can, isSite } = this.props;
    const CommentingStatusIcon = post.isCommentsDisabled ? CommentingEnabledIcon : CommentingDisabledIcon;

    return compact([
      includes(postActions, POST_ACTION_SUBSCRIBE) && (
        <ActionButton
          key={POST_ACTION_SUBSCRIBE}
          ref={this.getFirstButtonRef()}
          data-hook="post-actions__subscribe"
          onClick={
            can(POST_ACTION_SUBSCRIBE, 'post', post) ? this.handleSubscription : forPublicUser(this.handleSubscription)
          }
        >
          <SubscribeIcon isSubscribed={post.isSubscribed} className="blog-icon-fill" />
          {post.isSubscribed ? t('post-actions.unfollow') : t('post-actions.follow')}
        </ActionButton>
      ),
      includes(postActions, POST_ACTION_EDIT) && (
        <ActionButton
          ref={this.getFirstButtonRef()}
          key={POST_ACTION_EDIT}
          data-hook="post-actions__edit"
          onClick={can ? this.handleGoToEditPage : forPublicUser(this.handleGoToEditPage)}
        >
          <EditIcon className="blog-icon-fill" />
          {t('post-actions.edit-post')}
        </ActionButton>
      ),
      includes(postActions, POST_ACTION_SHARE) && (
        <ActionButton
          ref={this.getFirstButtonRef()}
          key={POST_ACTION_SHARE}
          data-hook="post-actions__share"
          onClick={isSite ? this.showModal(MODAL_TYPE_SHARE_POST) : noop}
        >
          <ShareIcon className="blog-icon-fill" />
          {t('post-actions.share-post')}
        </ActionButton>
      ),
      includes(postActions, POST_ACTION_PIN) && (
        <ActionButton
          ref={this.getFirstButtonRef()}
          key={POST_ACTION_PIN}
          data-hook="post-actions__pin"
          onClick={this.handlePin}
        >
          <PinIcon className="blog-icon-fill" />
          {post.isPinned ? t('post-actions.unpin-post') : t('post-actions.pin-post')}
        </ActionButton>
      ),
      includes(postActions, POST_ACTION_TOGGLE_COMMENTS) && (
        <ActionButton
          ref={this.getFirstButtonRef()}
          key={POST_ACTION_TOGGLE_COMMENTS}
          data-hook="post-actions__comments"
          onClick={this.handleComments}
        >
          <CommentingStatusIcon className="blog-icon-fill" />
          {post.isCommentsDisabled ? t('post-actions.enable-commenting') : t('post-actions.disable-commenting')}
        </ActionButton>
      ),
      includes(postActions, POST_ACTION_DELETE) && (
        <ActionButton
          ref={this.getFirstButtonRef()}
          key={POST_ACTION_DELETE}
          data-hook="post-actions__delete"
          onClick={this.showModal(MODAL_TYPE_DELETE_POST)}
        >
          <DeleteIcon className="blog-icon-fill" />
          {t('post-actions.delete-post')}
        </ActionButton>
      ),
      includes(postActions, POST_ACTION_DUPLICATE) && (
        <ActionButton
          ref={this.getFirstButtonRef()}
          key={POST_ACTION_DUPLICATE}
          data-hook="post-actions__duplicate"
          onClick={() => this.handleDuplicatePost()}
        >
          <DuplicateIcon className="blog-icon-fill" />
          {t('post-actions.duplicate-post')}
        </ActionButton>
      ),
    ]);
  };

  render() {
    return <div>{this.getButtons()}</div>;
  }
}

PostActions.propTypes = {
  t: PropTypes.func,
  currentUser: PropTypes.object,
  isOwner: PropTypes.bool,
  isMobile: PropTypes.bool,
  subscribe: PropTypes.func,
  unsubscribe: PropTypes.func,
  buttonClicked: PropTypes.func.isRequired,
  post: PropTypes.object.isRequired,
  categories: PropTypes.array.isRequired,
  openModal: PropTypes.func.isRequired,
  focusOnCloseId: PropTypes.string,
  showMessage: PropTypes.func.isRequired,
  pinPost: PropTypes.func.isRequired,
  unpinPost: PropTypes.func.isRequired,
  enablePostComments: PropTypes.func.isRequired,
  disablePostComments: PropTypes.func.isRequired,
  duplicatePost: PropTypes.func.isRequired,
  actionsOpened: PropTypes.func.isRequired,
  actionsClosed: PropTypes.func.isRequired,
  goToEditPage: PropTypes.func.isRequired,
  can: PropTypes.func.isRequired,
  forPublicUser: PropTypes.func.isRequired,
  postActions: PropTypes.array.isRequired,
  isPostEditorDeprecated: PropTypes.bool.isRequired,
  isSite: PropTypes.bool,
};

const mapRuntimeToProps = (state, { post, visibleActions, canSee }, actions) => ({
  categories: post.categoryIds.map(categoryId => getCategory(state, categoryId)),
  postActions: getPostActions({
    post,
    canSee,
    actions: visibleActions || POST_ACTIONS,
    enableShare: true,
    enableSubscribe: getIsMemberAreaInstalled(state),
  }),
  isPostEditorDeprecated: isPostEditorDeprecated(state),
  isSite: isSite(state),
  openModal: actions.openModal,
  subscribe: actions.subscribePromisified,
  unsubscribe: actions.unsubscribePromisified,
  actionsOpened: actions.actionsOpened,
  actionsClosed: actions.actionsClosed,
  pinPost: actions.pinPostPromisified,
  unpinPost: actions.unpinPostPromisified,
  enablePostComments: actions.enablePostCommentsPromisified,
  disablePostComments: actions.disablePostCommentsPromisified,
  duplicatePost: actions.duplicatePostPromisified,
  buttonClicked: actions.buttonClicked,
  goToEditPage: actions.goToEditPage,
  showMessage: actions.showMessage,
});

export default flowRight(
  withPermissions,
  connect(mapRuntimeToProps),
  withTranslate,
  withDeviceType,
  withAuth,
  withFeedMetadataSettings,
)(PostActions);
