import { flowRight } from 'lodash';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import classNames from 'classnames';

import LikeButtonWithCount from '../../../common/components/like-button-with-count';
import withTranslate from '../../../common/hoc/with-translate';
import withFontClassName from '../../../common/hoc/with-font-class-name';
import withAuth from '../../../common/hoc/with-auth';
import styles from './comment-footer.scss';

class CommentFooter extends Component {
  handleLikeClick = e => {
    const { comment, onLikeClick } = this.props;
    e.preventDefault();
    if (onLikeClick) {
      onLikeClick({ postId: comment.postId, commentId: comment._id });
    }
  };

  handleReplyClick = e => {
    const { comment, onReplyClick } = this.props;
    e.preventDefault();
    if (onReplyClick) {
      onReplyClick(comment._id);
    }
  };

  renderReplyLink = () => {
    const { t, showReplyLink } = this.props;
    return (
      showReplyLink && (
        <span onClick={this.handleReplyClick} className={classNames(styles.reply, 'comment-footer__reply')}>
          {t('comment.reply')}
        </span>
      )
    );
  };

  render = () => {
    const { comment, type, contentFontClassName, forPublicUser } = this.props;
    const className = classNames(styles.container, styles[type], 'blog-text-color', 'comment-footer');

    return (
      <div className={className}>
        {this.renderReplyLink()}
        <LikeButtonWithCount
          onClick={forPublicUser(this.handleLikeClick, { preventDefault: true })}
          entity={comment}
          className={classNames(styles.likeButton, contentFontClassName)}
          isComment={true}
        />
      </div>
    );
  };
}

CommentFooter.SEARCH = 'search';

CommentFooter.propTypes = {
  comment: PropTypes.object.isRequired,
  t: PropTypes.func.isRequired,
  type: PropTypes.string,
  onLikeClick: PropTypes.func,
  onReplyClick: PropTypes.func,
  showReplyLink: PropTypes.bool,
  contentFontClassName: PropTypes.string.isRequired,
  forPublicUser: PropTypes.func,
};

export default flowRight(
  withFontClassName,
  withTranslate,
  withAuth,
)(CommentFooter);
