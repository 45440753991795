import {
  SOCIAL_SHARING_FACEBOOK,
  SOCIAL_SHARING_TWITTER,
  SOCIAL_SHARING_LINKED_IN,
} from '@wix/communities-blog-client-common/dist/src/constants/social-sharing';

const WIDTH = 640;
const HEIGHT = 640;
const openShareDialog = url => {
  window.open(
    url,
    'Share',
    `width=${WIDTH},height=${HEIGHT},toolbar=no,menubar=no,scrollbars=no,location=no,status=no`,
  );
};

const getUrl = (socialNetwork, href) => {
  switch (socialNetwork) {
    case SOCIAL_SHARING_FACEBOOK:
      return `http://www.facebook.com/sharer/sharer.php?u=${href}`;
    case SOCIAL_SHARING_TWITTER:
      return `https://twitter.com/share?url=${href}`;
    case SOCIAL_SHARING_LINKED_IN:
      return `https://www.linkedin.com/shareArticle?mini=true&url=${href}`;
    default:
      return;
  }
};

export default function share(type, path) {
  openShareDialog(getUrl(type, path));
}
